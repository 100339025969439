/*
 * Copyright 2019 ThoughtWorks, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import m from "mithril";
import {f} from "helpers/form_helper";
import {mixins as s} from "helpers/string-plus";
import _ from "lodash";

export const ComponentMixins = {};

ComponentMixins.HasViewModel = function () {
  let vm;

  if (this.args && this.args.vm) {
    vm = this.args.vm || {};
    delete this.args.vm;
  }

  vm = vm || {};

  this.vmState = function (key, value) {
    if (!key) {
      return _.merge({}, vm);
    }
    return (vm[key] = vm[key] || value || {});
  };

  this.deleteVm = function (key) {
    delete vm[key];
  };
};

ComponentMixins.ManagesCollection = function (options) {
  const associationName                  = options.as;
  const associationNamePlural            = s.camelcase(s.defaultToIfBlank(options.plural, `${options.as}s`), true);
  const associationNamePluralCapitalized = s.camelcase(s.defaultToIfBlank(options.plural, `${options.as}s`));

  this.add = options.add || function () {
    this.args[associationNamePlural]()[`create${associationName}`]();
  };

  this.remove = function (thing) {
    this.args[associationNamePlural]()[`remove${associationName}`](thing);
    this.changed();
  };

  this.last = options.last || function () {
    return this.args[associationNamePlural]()[`last${associationName}`]();
  };

  this.changed = function () {
    const last = this.last();
    if (!(last && last.isBlank())) {
      this.add();
    }
  };

  this.removeLink = function (thing) {
    if (thing !== this.last()) {
      return (<f.button type='remove' class='position-remove-button' onclick={this.remove.bind(this, thing)}/>);
    }
  };

  this.map = options.map || function (callback) {
    return this.args[associationNamePlural]()[`map${associationNamePluralCapitalized}`]((thing, index) => {
      return callback(thing, index);
    });
  };

  options.onInitialize && options.onInitialize.call(this);
};
